import React from 'react'
import { graphql } from 'gatsby'

import Navbar from 'components/shared/Navbar'
import Footer from 'components/shared/Footer'
import Layout from 'components/shared/Layout'
import Background from 'components/Home/Background'
import Content from 'components/Home/Content'

const Homepage = ({ data }) => (
  <Layout>
    <Background fluid={data.background.childImageSharp.fluid} />

    <Navbar homepage />
    <Content />
    <Footer homepage />
  </Layout>
)

export const query = graphql`
  query {
    background: file(relativePath: { eq: "bg-homepage.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Homepage
