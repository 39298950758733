import React from 'react'
import FileIconScale from 'images/icon-scale.svg'
import { Wrapper, Title, Container, Column, Icon } from './styled'

const Content = () => (
  <Wrapper>
    <Icon src={FileIconScale} />

    <Title>Experience, Dedication, Creativity, and Efficiency</Title>

    <Container>
      <Column bold>
        We are a boutique law firm providing practical solutions and advice to
        complex problems.
      </Column>
      <Column>
        Our experience and size allows us to cater each representation to the
        particular client and situation.
        <br />
        <br />
        We represent companies, individuals, and whistleblowers. Our attorneys
        have years of experience in successfully resolving whistleblower,
        employment, civil rights, and complex commercial litigation actions.
      </Column>
    </Container>
  </Wrapper>
)

export default Content
