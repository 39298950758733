import styled from 'styled-components'
import { media } from 'themes'

export const Wrapper = styled.section`
  color: #ffffff;
  margin-top: 115px;
  text-align: center;
`

export const Title = styled.h1`
  width: 468px;
  font-family: 'Playfair Display';
  font-size: 40px;
  line-height: 56px;
  font-weight: bold;
  text-align: center;
  margin: 40px auto 0 auto;

  ${media.sm`
    width: auto;
    font-size: 24px;
    line-height: 40px;
  `}
`

export const Container = styled.div`
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 72px auto 126px auto;
  
  ${media.sm`
    display: block;
    width: 100%;
    padding: 0 16px;
    margin: 48px auto 71px auto;
  `}
`

export const Column = styled.div`
  flex: 1;
  text-align: left;

  ${({ bold }) => bold && `
    font-weight: 700;
  `}

  ${media.sm`
    margin-bottom: 24px;
  `}

  & + & {
    margin-left: 55px;

    ${media.sm`
      margin-left: 0;
    `}
  }
`

export const Icon = styled.img`

`
