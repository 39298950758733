import styled from 'styled-components'
import Img from 'gatsby-image'
import { isMobile } from 'themes/utils'

const Background = styled(Img).attrs({
  imgStyle: {
    objectPosition: `${isMobile ? '80%' : '90%'} top`,
  }
})`
  && {
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: -1;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
  }
`

export default Background
